import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { useAppContext } from '../../../../context/app';
import { DeliveryNavigationMode, useConsumeDataDeliveryState } from '../../../../context/dataDeliveryContext';
import { SvgIcon, Typography } from '@progress/kendo-react-common';
import { WrappedOrEllipsisSpan } from '../../../../components';
import { FileNode, FileNodeStatus } from '../../../../types/DataDelivery';
import { chevronLeftIcon, saveIcon } from '@progress/kendo-svg-icons';
import { useViewLayer, useViewLayerParamsUpdate, useViewLayerUpdate } from '../../../../hooks/viewerconfig';
import { AuthorityLevel, AuthorityType, SapFlowViewConfig, ViewLayer } from '../../../../types';
import VisualizationControls from './VisualizationControl';
import { useUser } from '../../../../hooks/authentication';
import { debounce } from 'lodash';
import { assignOrReplaceDescendant, findFileNodeRecursively } from '../../../../common/fileNodeHelper';
import { useQueryClient } from 'react-query';
import { deepDiff, isEmptyObject } from '../../../../common/objComparator';
import IconButton from '../../../../components/form/IconButton';
import { proj4ToEPSG } from '../../../../common/proj4Helper';
import FeatureProperties from './FeatureProperties';

const NavigationLayerInspector: React.FC = () => {
  const { userHasAuthority } = useUser();
  const { dispatch, rootFileNode, openedFileNode, selectedFeature } = useConsumeDataDeliveryState();
  const useViewLayerQuery = useViewLayer(null, openedFileNode?.viewLayer?.id);
  const [viewLayer, setViewLayer] = useState<ViewLayer>(null);
  const viewLayerUpdateMutation = useViewLayerUpdate();
  const viewLayerParamsUpdateMutation = useViewLayerParamsUpdate();
  const [layerHasChanges, setLayerHasChanges] = useState(true);
  const queryClient = useQueryClient();

  useEffect(() => {
    const viewLayer = useViewLayerQuery.data;
    setViewLayer(viewLayer);

    const ogViewLayer: ViewLayer = queryClient.getQueryData(['ogViewLayer', openedFileNode?.viewLayer?.id]);
    if (ogViewLayer && viewLayer) {
      //const viewLayerDiff = deepDiff(ogViewLayer.paramsMap, viewLayer.paramsMap);
      const diff = deepDiff(ogViewLayer.paramsMap, viewLayer.paramsMap);
      setLayerHasChanges(!isEmptyObject(diff));
      //console.log(JSON.stringify(diff));
    }

    //openedFileNode.viewLayer = viewLayer;
    //const newRoot = assignOrReplaceDescendant(rootFileNode, openedFileNode);
    //dispatch({ type: 'SET_ROOT', payload: JSON.parse(JSON.stringify(newRoot)) });
  }, [useViewLayerQuery.isSuccess, useViewLayerQuery.data, useViewLayerQuery.isRefetching]);

  const handleBack = () => {
    if (selectedFeature) {
      dispatch({ type: 'SET_SELECTED_FEATURE', payload: null });
    } else {
      if (openedFileNode.parent) {
        const parent = findFileNodeRecursively(rootFileNode, openedFileNode.parent.id);
        if (parent) {
          dispatch({ type: 'ZOOM_TO_FILENODE', payload: parent });
        }
      }
      dispatch({ type: 'SET_OPENED_FILE_NODE', payload: null });
    }
  };

  const updateViewLayerParams = (layer: ViewLayer) => {
    viewLayerParamsUpdateMutation.mutateAsync({ viewLayerId: layer.id, params: layer.params }).then(() => {
      //const viewConfig: SapFlowViewConfig = queryClient.getQueryData(['fileNodeView', fileNodeId]);
      //for (let i = 0; i < viewConfig.layers.length; i++) {
      //  if (viewConfig.layers[i].id === viewConfig.layers[i].id) {
      //    viewConfig.layers[i] = layer;
      //  }
      //}
      //queryClient.setQueryData(['fileNodeView', fileNodeId], viewConfig);
    });
    //viewerState.dispatch({ type: 'THROTTLE_STATE', payload: { throttling: false } });
  };

  const throttledViewLayerParamUpdate = useCallback(debounce(updateViewLayerParams, 2000), []);

  const handleLayerUpdate = async (layer: ViewLayer, persistent = false, immediate = false) => {
    //viewerState.dispatch({ type: 'LAYER_UPDATE', payload: { layer } });
    //console.log('updating layer: ' + layer.paramsMap.visualization.singleband.currentMax);
    const diff = deepDiff(viewLayer.paramsMap, layer.paramsMap);

    layer.lastUpdated = new Date().getTime();
    queryClient.setQueryData(['viewLayer', layer.id], layer);
    if (persistent && userHasAuthority(AuthorityType.VIEWER_AUTHORITY, AuthorityLevel.UPDATE)) {
      if (immediate) {
        updateViewLayerParams(layer);
      } else {
        //viewerState.dispatch({ type: 'THROTTLE_STATE', payload: { throttling: true } });
        throttledViewLayerParamUpdate(layer);
      }
      // TODO: Detect individual property modifications to build update request
      //let updateParams = { viewerId: viewerState.viewConfig.id, layerId: layer.id };
      //updateParams = { ...updateParams, ...{ displayName: layer.displayName } };
      //updateParams = { ...updateParams, ...{ active: layer.active } };
      //viewLayerUpdateMutation.mutateAsync(updateParams);
    }
  };

  const handleSaveLayer = () => {
    viewLayerParamsUpdateMutation.mutateAsync({ viewLayerId: viewLayer.id, params: viewLayer.params });
  };

  const renderContent = () => {
    if (selectedFeature) {
      return <FeatureProperties layer={viewLayer} onLayerUpdated={handleLayerUpdate} />;
    } else {
      return <VisualizationControls layer={viewLayer} onLayerUpdated={handleLayerUpdate} />;
    }
  };

  return (
    <div
      className=""
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        overflow: 'auto',
        position: 'relative',
        padding: '1rem',
        color: 'white',
      }}
    >
      <div className="d-flex">
        <div className="d-flex items-center" style={{ alignItems: 'center' }}>
          <SvgIcon
            icon={chevronLeftIcon}
            size="large"
            style={{
              marginRight: '12px',
              cursor: 'pointer',
              fontSize: '28px',
              marginLeft: '-8px',
            }}
            onClick={() => {
              handleBack();
            }}
          />
        </div>
        <Typography.h5
          className="d-none d-lg-flex"
          style={{
            padding: '0px 0px',
            display: 'flex',
            margin: '0 0',
            flex: 1,
            justifyContent: 'flex-start',
          }}
        >
          <div style={{ display: 'flex', width: '100%' }}>
            <div
              style={{ position: 'relative', flex: '1 1 0%', display: 'flex', overflow: 'hidden', marginRight: 'auto' }}
            >
              <WrappedOrEllipsisSpan wrapped={true}>{openedFileNode?.name}</WrappedOrEllipsisSpan>

              <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                <Tooltip anchorElement="target" position="top" style={{ maxWidth: '400px', whiteSpace: 'normal' }}>
                  <a
                    style={{ width: '100%', height: '100%', position: 'absolute', zIndex: 1 }}
                    title={openedFileNode?.name}
                  ></a>
                </Tooltip>
              </div>
            </div>
          </div>
          <IconButton></IconButton>
          {userHasAuthority(AuthorityType.VIEWER_AUTHORITY, AuthorityLevel.UPDATE) && (
            <SvgIcon
              icon={saveIcon}
              className="animated-color"
              style={{
                color: layerHasChanges ? 'white' : 'transparent',
                cursor: 'pointer',
                pointerEvents: layerHasChanges ? 'auto' : 'none',
              }}
              onClick={(e) => {
                if (layerHasChanges) handleSaveLayer();
              }}
            />
          )}
        </Typography.h5>
      </div>
      <Typography.p>Description: {openedFileNode?.description}</Typography.p>
      <Typography.p>Data of Origination: {openedFileNode?.createdDate}</Typography.p>
      <Typography.p>Coordinate Reference System: {proj4ToEPSG(viewLayer?.paramsMap?.projection)}</Typography.p>

      {renderContent()}
    </div>
  );
};

export default NavigationLayerInspector;
