export function rgbToHex(r: number, g: number, b: number): string {
    const toHex = (n: number) => {
        const hex = n.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    };

    return '#' + toHex(r) + toHex(g) + toHex(b);
}

export function hexToRgb(hex: string): { r: number; g: number; b: number } | null {
    if (!/^#?[0-9A-Fa-f]{6}$/.test(hex)) {
        return null; // Return null for invalid hex strings
    }

    hex = hex.replace(/^#/, ''); // Remove '#' if present

    return {
        r: parseInt(hex.substring(0, 2), 16),
        g: parseInt(hex.substring(2, 4), 16),
        b: parseInt(hex.substring(4, 6), 16),
    };
}