import { useContext, useEffect, useImperativeHandle, forwardRef, useState } from 'react';
import MapContext from '../Map/MapContext';
import OLVectorLayer from 'ol/layer/Vector';
import { Fill, Icon, Stroke, Style } from 'ol/style.js';
import { Feature } from 'ol';
import { easeOut } from 'ol/easing.js';
import { unByKey } from 'ol/Observable.js';
import VectorSource from 'ol/source/Vector';

interface Props {
  highlight: boolean;
  show: boolean;
  style: any;
  source: any;
  zIndex: number;
  color?: { r: number; g: number; b: number };
}

type Ref = {
  zoomToLayer: (layer: any) => void;
  getOlLayer: (layer: any) => any;
} | null;
const VectorLayer = forwardRef<Ref, Props>(({ show, highlight, source, style, zIndex = 0, color }, ref) => {
  const { map } = useContext<any>(MapContext);
  const [vectorLayer, SetVectorLayer] = useState<any>();
  const [defaultColor, setDefaultColor] = useState<any>({ r: 100, g: 100, b: 100 });
  const [highlightedColor, setHighlightedColor] = useState<any>({ r: 85, g: 137, b: 85 });

  useEffect(() => {
    if (!map) return;

    const newVectorLayer = new OLVectorLayer({
      source,
      style,
      visible: show,
    });

    map.addLayer(newVectorLayer);
    newVectorLayer.setZIndex(zIndex);
    SetVectorLayer(newVectorLayer);

    return () => {
      if (map) {
        map.removeLayer(newVectorLayer);
      }
    };
  }, [map]);

  useEffect(() => {
    if (!vectorLayer) return;
    vectorLayer.setVisible(show);
  }, [show]);

  useEffect(() => {
    if (color) {
      setDefaultColor(color);
      setHighlightedColor({ r: (color.r + 255) / 2, g: (color.g + 255) / 2, b: (color.b + 255) / 2 });
    }
  }, [color]);

  useEffect(() => {
    if (!vectorLayer) return;

    const duration = 250;
    function flash(feature: Feature, growing: boolean) {
      const start = Date.now();
      const listenerKey = vectorLayer.on('postrender', animate);
      map.render();

      function animate(event: any) {
        const frameState = event.frameState;
        const elapsed = frameState.time - start;
        if (elapsed >= duration) {
          unByKey(listenerKey);
          return;
        }
        const elapsedRatio = elapsed / duration;
        let r = defaultColor.r + easeOut(elapsedRatio) * (highlightedColor.r - defaultColor.r);
        let g = defaultColor.g + easeOut(elapsedRatio) * (highlightedColor.g - defaultColor.g);
        let b = defaultColor.b + easeOut(elapsedRatio) * (highlightedColor.b - defaultColor.b);
        if (!growing) {
          r = highlightedColor.r - easeOut(elapsedRatio) * (highlightedColor.r - defaultColor.r);
          g = highlightedColor.g - easeOut(elapsedRatio) * (highlightedColor.g - defaultColor.g);
          b = highlightedColor.b - easeOut(elapsedRatio) * (highlightedColor.b - defaultColor.b);
        }
        const style = new Style({
          stroke: new Stroke({
            width: 0.75,
            color: 'white',
          }),
          fill: new Fill({
            color: [r, g, b, 1],
          }),
        });

        feature.setStyle(style);
        // tell OpenLayers to continue postrender animation
        map.render();
      }
    }

    (vectorLayer.getSource() as VectorSource).getFeatures().forEach((f: any) => {
      flash(f, highlight);
    });
  }, [highlight, vectorLayer]);

  const zoomToLayer = (layer: any) => {
    map.getView().fit(source.getExtent());
  };

  const getOlLayer = (layer: any) => {
    return vectorLayer;
  };

  useImperativeHandle(ref, () => ({
    zoomToLayer,
    getOlLayer,
  }));

  return null;
});
VectorLayer.displayName = 'VectorLayer';

export default VectorLayer;
