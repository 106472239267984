import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useGenerateViewToken } from '../../hooks/user_management';
import { useAppContext } from '../../context/app';
import { useUser } from '../../hooks/authentication';

interface Props {
  children: any;
}

const SharedWrapper: React.FC<Props> = ({ children }) => {
  const { dispatch, selectedOrganization: currentOrg } = useAppContext();
  const [queryParameters] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const accessId = queryParameters.get('accessId');
  const generateViewTokenQuery = useGenerateViewToken(accessId);
  const { getCurrentOrganization } = useUser();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!accessId) {
      //dispatch({
      //  type: 'SHOW_NOTIFICATION',
      //  payload: { notification: { type: 'error', content: 'Share Code invalid. Redirecting.' } },
      //});
      //setTimeout(() => {
      //  navigate('/login');
      //}, 1500);
    } else if (generateViewTokenQuery.isSuccess) {
      let newSelectedOrganization = null;
      if (generateViewTokenQuery.data.organizations && generateViewTokenQuery.data.organizations?.length === 1) {
        newSelectedOrganization = generateViewTokenQuery.data.organizations[0];
      }

      if (currentOrg && currentOrg.id === newSelectedOrganization.id) {
        const newPath = location.pathname.replace('/shared-delivery', '/delivery');
        navigate(newPath, { replace: true });
        return;
      }

      // Build the userAuthority mapping
      if (newSelectedOrganization?.userRole) {
        const userAuth: any = {};
        newSelectedOrganization.userRole.authorities.forEach((auth) => {
          userAuth[auth.permissionType] = auth;
        });
        newSelectedOrganization.userRole.authoritiesMap = userAuth;
      }
      dispatch({
        type: 'VIEW_USER',
        payload: {
          viewAccessToken: generateViewTokenQuery.data.token,
          filenode: generateViewTokenQuery.data.filenode,
          transaction: generateViewTokenQuery.data.transaction,
          organizations: generateViewTokenQuery.data.organizations,
        },
      });
      dispatch({ type: 'UPDATE_VIEW_ORGANIZATION', payload: { organization: newSelectedOrganization } });
      //if (generateViewTokenQuery.data.transaction) {
      //  navigate('/shared-viewer/' + generateViewTokenQuery.data.transaction.id + '?accessId=' + shareId);
      //} else if (generateViewTokenQuery.data.filenode) {
      //  if (generateViewTokenQuery.data.filenode.isDir) {
      //    navigate('/shared-delivery/' + generateViewTokenQuery.data.filenode.id + '?accessId=' + shareId);
      //  } else {
      //    navigate(
      //      '/shared-delivery/' +
      //        generateViewTokenQuery.data.filenode.rootId +
      //        '/' +
      //        generateViewTokenQuery.data.filenode.id +
      //        '?accessId=' +
      //        shareId
      //    );
      //  }
      //} else {
      //  dispatch({
      //    type: 'SHOW_NOTIFICATION',
      //    payload: {
      //      notification: { type: 'error', content: 'Shared resource not found. Please contact administrator.' },
      //    },
      //  });
      //  setTimeout(() => {
      //    navigate('/login');
      //  }, 1500);
      //}
      setReady(true);
    } else if (generateViewTokenQuery.isError) {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { type: 'error', content: 'Problem occured with shared resource. Try again later.' } },
      });
      setTimeout(() => {
        navigate('/login');
      }, 1500);
    }
  }, [generateViewTokenQuery.isLoading]);
  if (!ready) {
    return null;
  }
  return children;
};

export default SharedWrapper;
