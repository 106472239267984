import { useContext, useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react';
import MapContext from '../Map/MapContext';
import Extent from 'ol/extent';
import { transformExtent } from 'ol/proj.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import { bbox as bboxStrategy } from 'ol/loadingstrategy.js';
import VectorSource from 'ol/source/Vector';
import { LayerType, ViewLayer } from '../../../../types';
import useViewLayer from '../../../../hooks/viewerconfig/useViewLayer';
import VectorLayer from './VectorLayer';
import React from 'react';
import { FileNode, FileNodeType, FileNodeViewType } from '../../../../types/DataDelivery';
import { getWfsPreviewLayerUrl } from '../../../../common/viewLayerHelper';
import AnnotationLayer from './AnnotationLayer';
import { hexToRgb } from '../../../../converters/colorHelper';

const proj4 = window.proj4 || require('proj4');

interface AOIProps {
  fileNode: FileNode;
  focusedFileNode: FileNode;
  layer?: ViewLayer;
  displayedFileNodes: FileNode[];
  show: boolean;
  zIndex: number;
  style?: any;
  onLayerUpdated?: (layer: ViewLayer) => void;
}

type Ref = {
  zoomToLayer: (layer: any) => void;
} | null;
const AOILayer = forwardRef<Ref, AOIProps>((props, ref) => {
  const { map } = useContext<any>(MapContext);
  const useViewLayerQuery = useViewLayer(props.layer, props.fileNode?.viewLayer?.id);
  const [layer, setLayer] = useState(null);
  //const lowResolutionRef = useRef<any>(null);
  const highResolutionRef = useRef<any>(null);
  const [aoiFileNodes, setAOIFileNodes] = useState([]);

  useEffect(() => {
    setLayer(useViewLayerQuery.data);
    map.getView().on('change:resolution', function () {
      const zoom = map.getView().getZoom();
      const zoomThreshold =
        useViewLayerQuery.data && useViewLayerQuery.data.paramsMap['minZoomLevel']
          ? useViewLayerQuery.data.paramsMap['minZoomLevel']
          : 12;
      if (zoom > zoomThreshold && highResolutionRef.current?.getOlLayer()) {
        //lowResolutionRef.current?.getOlLayer()?.setVisible(false);
        //highResolutionRef.current?.getOlLayer()?.setVisible(true);
      } else {
        //lowResolutionRef.current?.getOlLayer()?.setVisible(true);
        //highResolutionRef.current?.getOlLayer()?.setVisible(false);
      }
    });
  }, [useViewLayerQuery.isSuccess, useViewLayerQuery.data]);

  useEffect(() => {
    setAOIFileNodes(
      props.displayedFileNodes?.filter((f) => {
        return !f.isSupport && !f.supportingFile;
      })
    );
  }, [props.displayedFileNodes]);

  const zoomToLayer = (layer: any) => {
    const projection = props.layer?.paramsMap['projection'];
    const extents = props.layer?.paramsMap['extents'];
    if (projection && extents) {
      let olExtents: Extent.Extent = [extents[0], extents[1], extents[2], extents[3]];
      if (projection !== map.getView().getProjection().getCode()) {
        olExtents = transformExtent(olExtents, projection, map.getView().getProjection().getCode());
      }
      map.getView().fit(olExtents);
    }
  };

  useImperativeHandle(ref, () => ({
    zoomToLayer,
  }));

  const getAOILayer = (filenode: FileNode, index: number) => {
    if (!props.fileNode.viewTypes.includes(FileNodeViewType.AOI)) {
      return null;
    }
    if (!filenode.isDir) return null;
    if (layer?.layerType === LayerType.GSVEC /* && props.fileNode.isSupport*/ && layer.paramsMap['uri']) {
      let url = layer.paramsMap['uri'];
      if (!url.endsWith('/')) {
        url = url.concat('/');
      }
      url = url + 'wfs';
      const layerName =
        (layer.paramsMap['workspace'] ? layer.paramsMap['workspace'] + ':' : '') + layer.paramsMap['layer'];
      const source = new VectorSource({
        format: new GeoJSON(),
        loader: function (extent, resolution, projection, success, failure) {
          const proj = projection.getCode();

          const featureUrl = encodeURI(
            `${url}?service=WFS&version=${layer.paramsMap['version']}&request=GetFeature&typename=${layerName}&outputFormat=application/json&srsname=EPSG:3857&CQL_FILTER=site LIKE '%${filenode.name}%'`
          );
          const xhr = new XMLHttpRequest();
          xhr.open('GET', featureUrl);
          const onError = function () {
            source.removeLoadedExtent(extent);
            failure();
          };
          xhr.onerror = onError;
          xhr.onload = function () {
            if (xhr.status === 200) {
              const features: any = source.getFormat().readFeatures(xhr.responseText);
              features.forEach((feature: any) => {
                feature.setProperties({ interactable: true, fileNode: filenode });
              });
              source.addFeatures(features);
              success(features);
            } else {
              onError();
            }
          };
          xhr.send();
        },
        strategy: bboxStrategy,
      });
      return (
        <VectorLayer
          key={filenode.id}
          ref={(el: any) => {
            highResolutionRef.current = el;
          }}
          show={props.show}
          highlight={props.focusedFileNode?.id === filenode.id}
          zIndex={1}
          color={hexToRgb(filenode.color)}
          style={{
            'stroke-width': 0.75,
            'stroke-color': 'white',
            'fill-color': filenode.color ? filenode.color : 'rgb(116,116,116)', //'rgba(100,100,100,0.25)',
          }}
          source={source}
        ></VectorLayer>
      );
    }
    return null;
  };

  return (
    <div>
      {aoiFileNodes?.map((fileNode, index) => {
        return getAOILayer(fileNode, index);
      })}
    </div>
  );
});

AOILayer.displayName = 'AOILayer';

export default AOILayer;
