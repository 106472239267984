import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Typography } from '@progress/kendo-react-common';
import { SvgIcon } from '@progress/kendo-react-common';
import { trackChangesEnableIcon, checkIcon } from '@progress/kendo-svg-icons';
import Dialog from '../../components/Dialog';
import { FileNode, FileNodeTag, FileNodeType, FileNodeViewType } from '../../types/DataDelivery';
import { formatSizeUnits } from '../../converters/formatSizeUnits';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import { useFileNodeRename, useFileNodeUpdate } from '../../hooks/data_delivery';
import { Loader } from '@progress/kendo-react-indicators';
import { findFileNodeRecursively, getFileNodeTag } from '../../common/fileNodeHelper';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { StyledDialog } from '../../components/styled';
import FileNodeTreeView from './FileNodeTreeView/FileNodeTreeView';
import { useViewLayer, useViewLayerParamsUpdate } from '../../hooks/viewerconfig';
import { updateLayerParam } from '../../common/viewerConfigHelper';
import { LayerType, UserRole } from '../../types';
import { SubmitButton } from '../../components/form';
import { useUser } from '../../hooks/authentication';

interface DialogProps {
  show: boolean;
  onClose: any;
  rootFileNode: FileNode;
  fileNode: FileNode;
  //onStyling: (fileNode: FileNode) => void;
}

const PropertiesPanel: React.FC<DialogProps> = (props: DialogProps) => {
  const queryClient = useQueryClient();
  const { userHasRole } = useUser();
  const [hovering, setHovering] = React.useState(null);
  const [modifying, setModifying] = React.useState(null);
  const [modifyingValue, setModifyingValue] = React.useState(null);
  const [viewLayerIdToFetch, setViewLayerIdToFetch] = React.useState(null);
  const useViewLayerQuery = useViewLayer(null, viewLayerIdToFetch);
  const [currentFileNodeSubTags, setCurrentFileNodeSubTags] = React.useState<FileNodeTag[]>([]);
  const filenodeUpdateMutation = useFileNodeUpdate();
  const filenodeRenameMutation = useFileNodeRename();
  const viewLayerParamsUpdateMutation = useViewLayerParamsUpdate();

  useEffect(() => {
    setModifying(null);
    setModifyingValue(null);
    setHovering(null);
    const newFileNodeSubTags: FileNodeTag[] = [];
    if (props.fileNode) {
      if (props.fileNode.children && props.fileNode.children.length > 0) {
        const recursiveTagSearch = (fileNode: FileNode) => {
          if (fileNode.isDir && fileNode.children) {
            fileNode.children.forEach((childNode) => {
              recursiveTagSearch(childNode);
            });
          } else if (fileNode.fileType === FileNodeType.SUPPORT) {
            // do nothing
          } else {
            const fileNodeTag = getFileNodeTag(fileNode);
            if (
              fileNodeTag &&
              newFileNodeSubTags.filter((existingTag) => {
                return existingTag.text === fileNodeTag.text;
              }).length === 0
            ) {
              newFileNodeSubTags.push(fileNodeTag);
            }
          }
        };

        props.fileNode.children.forEach((childNode) => {
          recursiveTagSearch(childNode);
        });
      }
      if (props.fileNode.viewLayer) {
        setViewLayerIdToFetch(props.fileNode.viewLayer.id);
      }
    }
    setCurrentFileNodeSubTags(newFileNodeSubTags);
  }, [props.fileNode]);

  const handleModify = (propertyName: string, value: string) => {
    if (propertyName === 'Name') {
      filenodeRenameMutation.mutateAsync({ fileNodeId: props.fileNode.id, newName: value }).then(() => {
        setHovering(null);
        setModifying(null);
        setModifyingValue(null);
      });
    }
    if (propertyName === 'Description') {
      filenodeUpdateMutation.mutateAsync({ fileNodeId: props.fileNode.id, description: value }).then(() => {
        setHovering(null);
        setModifying(null);
        setModifyingValue(null);
      });
    }
    if (propertyName === 'Zoom level' && useViewLayerQuery.data) {
      const newViewLayer = JSON.parse(JSON.stringify(useViewLayerQuery.data));
      updateLayerParam(newViewLayer, 'minZoomLevel', parseFloat(modifyingValue));
      viewLayerParamsUpdateMutation
        .mutateAsync({ viewLayerId: newViewLayer.id, params: newViewLayer.params })
        .then(() => {
          setHovering(null);
          setModifying(null);
          setModifyingValue(null);
        });
    }
    if (propertyName === 'supportingFile') {
      filenodeUpdateMutation.mutateAsync({ fileNodeId: props.fileNode.id, supportingFileNodeId: value }).then(() => {
        setHovering(null);
        setModifying(null);
        setModifyingValue(null);
      });
    }
  };

  const renderProperty = (condition: boolean, label: string, children: JSX.Element) => {
    if (!condition) return null;
    return (
      <div style={{ display: 'flex' }}>
        <b>{label}:</b>
        {children}
      </div>
    );
  };

  const renderPropertyData = (name: string, value: string | number, modifiable: boolean) => {
    return (
      <div
        style={{ position: 'relative' }}
        onMouseEnter={() => {
          if (!modifying && modifiable) setHovering(name);
        }}
        onMouseLeave={() => {
          setHovering(null);
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <b>{name}</b>:&nbsp;{modifying !== name && value}
          {modifying === name && (
            <Input
              style={{ padding: '0.25rem !important', marginRight: '2rem' }}
              type="text"
              name={name}
              value={modifyingValue}
              onChange={(e) => {
                setModifyingValue(e.value);
              }}
            />
          )}
        </div>
        {hovering === name && (
          <SvgIcon
            icon={trackChangesEnableIcon}
            style={{
              color: 'black',
              fontSize: '1.2rem',
              cursor: 'pointer',
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
              margin: 'auto',
            }}
            onClick={() => {
              setModifying(name);
              setModifyingValue(value);
              setHovering(null);
            }}
          />
        )}
        {modifying === name && filenodeUpdateMutation.isLoading && (
          <Loader
            style={{
              color: 'black',
              fontSize: '1.2rem',
              cursor: 'pointer',
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
              margin: 'auto',
            }}
          />
        )}
        {modifying === name && !filenodeUpdateMutation.isLoading && (
          <SvgIcon
            icon={checkIcon}
            style={{
              color: 'black',
              fontSize: '1.2rem',
              cursor: 'pointer',
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
              margin: 'auto',
            }}
            onClick={() => {
              handleModify(name, modifyingValue);
            }}
          />
        )}
      </div>
    );
  };

  const renderFileNodeTag = (tag: FileNodeTag) => {
    return (
      <span
        style={{
          padding: '4px 10px',
          borderRadius: 12,
          backgroundColor: tag.background,
          color: 'white',
          height: 'fit-content',
          fontSize: '1.01rem',
          marginRight: '0.5rem',
        }}
      >
        {tag.text}
      </span>
    );
  };

  const renderSupportingFiles = () => {
    const name = 'supportingFile';
    if (props.fileNode?.supportingFile) {
      return (
        <div
          style={{ position: 'relative' }}
          onMouseEnter={() => {
            if (!modifying) setHovering(name);
          }}
          onMouseLeave={() => {
            setHovering(null);
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {modifying !== name && props.fileNode?.supportingFile.name}
            {modifying === name && (
              <div style={{}}>
                <FileNodeTreeView
                  rootFileNode={props.rootFileNode}
                  selectedFileNode={modifyingValue}
                  onItemSelected={(fileNode: FileNode) => {
                    setModifyingValue(fileNode);
                  }}
                ></FileNodeTreeView>
              </div>
            )}
          </div>
          {hovering === name && (
            <SvgIcon
              icon={trackChangesEnableIcon}
              style={{
                color: 'black',
                fontSize: '1.2rem',
                cursor: 'pointer',
                position: 'absolute',
                right: 0,
                top: 0,
                bottom: 0,
                margin: 'auto',
              }}
              onClick={() => {
                setModifying(name);
                setModifyingValue(props.fileNode?.supportingFile);
                setHovering(null);
              }}
            />
          )}
          {modifying === name && filenodeUpdateMutation.isLoading && (
            <Loader
              style={{
                color: 'black',
                fontSize: '1.2rem',
                cursor: 'pointer',
                position: 'absolute',
                right: 0,
                top: 0,
                bottom: 0,
                margin: 'auto',
              }}
            />
          )}
          {modifying === name && !filenodeUpdateMutation.isLoading && (
            <SvgIcon
              icon={checkIcon}
              style={{
                color: 'black',
                fontSize: '1.2rem',
                cursor: 'pointer',
                position: 'absolute',
                right: 0,
                top: 0,
                bottom: 0,
                margin: 'auto',
              }}
              onClick={() => {
                handleModify(name, modifyingValue?.id);
              }}
            />
          )}
        </div>
      );
    }
    return <span>Not supporting any file.</span>;
  };

  const renderLoaderOverlay = () => {
    return (
      <div
        style={{
          background: 'fcfcfcaa',
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
        }}
      >
        <Loader size="small" type={'converging-spinner'} themeColor={'primary'} />
      </div>
    );
  };

  return (
    <StyledDialog
      title={`Properties`}
      onClose={props.onClose}
      show={props.show}
      style={{ width: '40%', minWidth: '380px', overflow: 'hidden' }}
    >
      {filenodeUpdateMutation.isLoading && renderLoaderOverlay()}
      <div style={{ display: 'flex', flexDirection: 'column', color: 'rgb(136,136,136)', fontSize: '1.2rem' }}>
        <Typography.h5
          style={{
            color: 'rgb(105,105,105)',
          }}
        >
          {renderPropertyData('Name', props.fileNode?.name, userHasRole(UserRole.ROLE_SYSTEMADMIN))}
        </Typography.h5>
        {renderPropertyData('Description', props.fileNode?.description, true)}
        {props.fileNode?.size &&
          renderPropertyData(
            `${props.fileNode?.isDir ? 'Directory' : 'File'} Size`,
            formatSizeUnits(props.fileNode.size),
            false
          )}
        {renderPropertyData('Created Date', props.fileNode?.createdDate, false)}
        {renderPropertyData('Last Updated Date', props.fileNode?.lastModifiedDate, false)}
        {renderProperty(userHasRole(UserRole.ROLE_SYSTEMADMIN), 'Type', <span>&nbsp;{props.fileNode?.fileType}</span>)}
        {props.fileNode?.isDir &&
          renderPropertyData(
            'Number of items in directory',
            props.fileNode?.children?.filter((childFileNode: FileNode) => {
              return childFileNode.supportingFile === null;
            }).length,
            false
          )}
        {currentFileNodeSubTags.length > 0 && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <b>Tags for file types:</b>
            <div style={{ display: 'flex' }}>{currentFileNodeSubTags.map((tag) => renderFileNodeTag(tag))}</div>
          </div>
        )}
        {renderProperty(
          userHasRole(UserRole.ROLE_SYSTEMADMIN),
          'Support',
          <Checkbox
            style={{ marginLeft: '1rem' }}
            label={''}
            value={props.fileNode?.isSupport}
            onChange={(e) => {
              filenodeUpdateMutation.mutateAsync({ fileNodeId: props.fileNode.id, support: e.value });
            }}
          />
        )}
        {renderProperty(
          userHasRole(UserRole.ROLE_SYSTEMADMIN),
          'Persistent',
          <Checkbox
            style={{ marginLeft: '1rem' }}
            label={''}
            value={props.fileNode?.isPersistent}
            onChange={(e) => {
              filenodeUpdateMutation.mutateAsync({ fileNodeId: props.fileNode.id, persistent: e.value });
            }}
          />
        )}
        {userHasRole(UserRole.ROLE_SYSTEMADMIN) && props.fileNode?.isSupport && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <b>Support file of:</b>
            {renderSupportingFiles()}
          </div>
        )}
        {userHasRole(UserRole.ROLE_SYSTEMADMIN) && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <b>Displays:</b>
            <Checkbox
              style={{ marginLeft: '2rem' }}
              label={'Map View'}
              value={props.fileNode?.viewTypes?.includes(FileNodeViewType.MAP)}
              onChange={(e) => {
                const newViewTypes = props.fileNode?.viewTypes ? props.fileNode?.viewTypes : [];
                if (e.value && !newViewTypes.includes(FileNodeViewType.MAP)) {
                  newViewTypes.push(FileNodeViewType.MAP);
                } else if (!e.value && newViewTypes.includes(FileNodeViewType.MAP)) {
                  newViewTypes.splice(newViewTypes.indexOf(FileNodeViewType.MAP), 1);
                }
                filenodeUpdateMutation.mutateAsync({ fileNodeId: props.fileNode.id, viewTypes: newViewTypes });
              }}
            />
            <Checkbox
              style={{ marginLeft: '2rem' }}
              label={'Asset Viewer'}
              value={props.fileNode?.viewTypes?.includes(FileNodeViewType.VIEWER)}
              onChange={(e) => {
                const newViewTypes = props.fileNode?.viewTypes ? props.fileNode?.viewTypes : [];
                if (e.value && !newViewTypes.includes(FileNodeViewType.VIEWER)) {
                  newViewTypes.push(FileNodeViewType.VIEWER);
                } else if (!e.value && newViewTypes.includes(FileNodeViewType.VIEWER)) {
                  newViewTypes.splice(newViewTypes.indexOf(FileNodeViewType.VIEWER), 1);
                }
                filenodeUpdateMutation.mutateAsync({ fileNodeId: props.fileNode.id, viewTypes: newViewTypes });
              }}
            />
            {props.fileNode?.fileType === FileNodeType.GSVEC && (
              <Checkbox
                style={{ marginLeft: '2rem' }}
                label={'AOI Viewer'}
                value={props.fileNode?.viewTypes?.includes(FileNodeViewType.AOI)}
                onChange={(e) => {
                  const newViewTypes = props.fileNode?.viewTypes ? props.fileNode?.viewTypes : [];
                  if (e.value && !newViewTypes.includes(FileNodeViewType.AOI)) {
                    newViewTypes.push(FileNodeViewType.AOI);
                  } else if (!e.value && newViewTypes.includes(FileNodeViewType.AOI)) {
                    newViewTypes.splice(newViewTypes.indexOf(FileNodeViewType.AOI), 1);
                  }
                  filenodeUpdateMutation.mutateAsync({ fileNodeId: props.fileNode.id, viewTypes: newViewTypes });
                }}
              />
            )}
            {props.fileNode?.viewLayer &&
              useViewLayerQuery.isSuccess &&
              renderPropertyData(
                'Zoom level',
                useViewLayerQuery.data?.paramsMap['minZoomLevel'] !== null &&
                  useViewLayerQuery.data?.paramsMap['minZoomLevel'] !== undefined
                  ? useViewLayerQuery.data?.paramsMap['minZoomLevel']
                  : 0,
                true
              )}
          </div>
        )}
      </div>
    </StyledDialog>
  );
};

export default PropertiesPanel;
