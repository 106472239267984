import React, { useEffect, useState, useRef } from 'react';
//import * as proj4 from 'proj4';
import styled from 'styled-components';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useUser } from '../hooks/authentication';
import { useGenerateViewToken } from '../hooks/user_management';
import { AuthorityLevel, AuthorityType, LayerType, ViewConfigUpdateDTO, ViewLayer } from '../types';
import { useAppContext } from '../context/app';

const RestrictedViewer: React.FC = () => {
  const { dispatch, selectedOrganization: currentOrg } = useAppContext();
  const { shareId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [showPage, setShowPage] = useState<boolean>(false);
  const { transactionId, rootId, fileNodeId } = useParams();
  const { getUser, getViewAccessToken } = useUser();
  const [queryParameters] = useSearchParams();
  const generateViewTokenQuery = useGenerateViewToken(shareId);

  useEffect(() => {
    if (!shareId) {
      //dispatch({
      //  type: 'SHOW_NOTIFICATION',
      //  payload: { notification: { type: 'error', content: 'Share Code invalid. Redirecting.' } },
      //});
      //setTimeout(() => {
      //  navigate('/login');
      //}, 1500);
    } else if (generateViewTokenQuery.isSuccess) {
      let selectedOrganization = null;
      if (generateViewTokenQuery.data.organizations && generateViewTokenQuery.data.organizations?.length === 1) {
        selectedOrganization = generateViewTokenQuery.data.organizations[0];
      }

      if (currentOrg && selectedOrganization.id === currentOrg.id) {
        if (generateViewTokenQuery.data.transaction) {
          navigate('', { replace: true });
        } else if (generateViewTokenQuery.data.filenode) {
          if (generateViewTokenQuery.data.filenode.isDir) {
            navigate('/delivery/' + generateViewTokenQuery.data.filenode.id);
          } else {
            navigate(
              '/delivery/' + generateViewTokenQuery.data.filenode.rootId + '/' + generateViewTokenQuery.data.filenode.id
            );
          }
        }
        return;
      }

      // Build the userAuthority mapping
      if (selectedOrganization?.userRole) {
        const userAuth: any = {};
        selectedOrganization.userRole.authorities.forEach((auth) => {
          userAuth[auth.permissionType] = auth;
        });
        selectedOrganization.userRole.authoritiesMap = userAuth;
      }
      dispatch({
        type: 'VIEW_USER',
        payload: {
          viewAccessToken: generateViewTokenQuery.data.token,
          filenode: generateViewTokenQuery.data.filenode,
          transaction: generateViewTokenQuery.data.transaction,
          organizations: generateViewTokenQuery.data.organizations,
        },
      });
      dispatch({ type: 'UPDATE_VIEW_ORGANIZATION', payload: { organization: selectedOrganization } });
      if (generateViewTokenQuery.data.transaction) {
        navigate('/shared-viewer/' + generateViewTokenQuery.data.transaction.id + '?accessId=' + shareId);
      } else if (generateViewTokenQuery.data.filenode) {
        if (generateViewTokenQuery.data.filenode.isDir) {
          navigate('/shared-delivery/' + generateViewTokenQuery.data.filenode.id + '?accessId=' + shareId);
        } else {
          navigate(
            '/shared-delivery/' +
              generateViewTokenQuery.data.filenode.rootId +
              '/' +
              generateViewTokenQuery.data.filenode.id +
              '?accessId=' +
              shareId
          );
        }
      } else {
        dispatch({
          type: 'SHOW_NOTIFICATION',
          payload: {
            notification: { type: 'error', content: 'Shared resource not found. Please contact administrator.' },
          },
        });
        setTimeout(() => {
          navigate('/login');
        }, 1500);
      }
    } else if (generateViewTokenQuery.isError) {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { type: 'error', content: 'Problem occured with shared resource. Try again later.' } },
      });
      setTimeout(() => {
        navigate('/login');
      }, 1500);
    }
  }, [generateViewTokenQuery.isLoading]);

  useEffect(() => {
    if (getViewAccessToken()) {
      setShowPage(true);
    }
  }, [getViewAccessToken()]);

  return null;
};

export default RestrictedViewer;
